@import '../partials';
@import './AssetsVendor/selectboxit';

// @font-face {
//   font-family: 'weiss-bold';
//   src: url('Fonts/WeissStd-Bold.otf');
//   font-weight: normal;
//   font-style: normal;
// }

html {
  box-sizing: border-box;
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  font-family: $sans-serif-font;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $sans-serif-font;
}

a {
  color: $black;
  text-decoration: none;

  &:focus {
    outline: 1px solid $orange;
  }
}

ul {
  margin: 0;
  padding: 0;
}

.visually-hidden,
.visuallyhidden {
  @include visually-hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  @media (min-width: 1200px){
    width: $container-width;
  }
}

.component-boundary {
  @include section-padding;
}

.section-header {
  $c: &;

  text-align: center;

  &__title {
    @include section-title($white, $white);
  }

  &__text {
    @include section-text($white);
  }

  &--dark {
    #{$c}__title {
      @include section-title($white, $sky);
    }

    #{$c}__text {
      @include section-text($white);
    }
  }

  &--light {
    #{$c}__title {
      @include section-title($black, $blue);
    }

    #{$c}__text {
      @include section-text($black);
    }
  }
}

.section-footer {
  $c: &;

  text-align: center;

  &__text {
    @include section-text($white);
  }

  &--dark {

    #{$c}__text {
      @include section-text($white);
    }
  }

  &--light {

    #{$c}__text {
      @include section-text($black);
    }
  }
}

.button-link {
  @include primary-button;

  &--light {
    @include primary-button($white, $black);
  }

  &--dark {
    @include primary-button($blue, $white);
  }
}

.arrow-link {
  @include arrow-link;

  &--light {
    @include arrow-link($white);
  }

  &--dark {
    @include arrow-link($blue);
  }
}

.caption-image {
  position: relative;
  max-width: rem(630);
  flex-basis: 100%;

  @include bp(md){
    max-width: unset;
    flex-basis: calc(50% - 20px);
  }

  &__title {
    font-size: rem(14);

    @include bp(md){
      font-size: rem(22);
    }
  }

  &__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255,0.75);
    padding: rem(20) rem(30);
    font-size: rem(14);
    font-weight: $semi-bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include bp(md){
      padding: rem(30) rem(40);
    }
  }

  &__link {
    @include arrow-link;
    white-space: nowrap;
    margin-left: rem(20);
  }

  img {
    width: 100%;
  }
}